import React from 'react';
import { useState } from 'react';
import ReactDOM from 'react-dom/client';

// React Icons
import { 

	IoInformation,
	IoDocumentSharp,
	IoBriefcaseSharp,

	IoMail, 
	IoLogoLinkedin,
	IoLogoGithub, 
	IoLogoSkype, 
	IoLogoInstagram

} from "react-icons/io5";

// General CSS
import './css/main.css';

// Landscape CSS
import './css/landscape/main.css';
import './css/landscape/page-1.css';
import './css/landscape/page-2.css';
import './css/landscape/page-3.css';

// Portrait CSS
import './css/portrait/main.css';

import roomImage from './images/roomImage.PNG'

function Projects(props) {
	const projects = [
		{
			'title': 'Is Max Winning?',
			'description': 'A website for Max Verstappen fans. It retrieves live data from F1 streams and displays a simple "yes" or "no" to "Is Max Winning?". It also shows some additional information.',
			'skills': ['Node.js', 'APIs', 'JavaScript', 'HTML/CSS'],
			'links': [
				{
					'name': 'ismaxwinning.com',
					'link': 'https://ismaxwinning.com/'
				}
			]
		},
		{
			'title': 'Course on C#',
			'description': 'A course on C# for Codefinity.',
			'skills': ['Golang', 'Curriculum Development', 'Content Writing'],
			'links': [
				{
					'name': 'codefinity.com/c#',
					'link': 'https://codefinity.com/courses/v2/5e212c93-7f58-440d-b0d3-1446fb2b42b3'
				}
			]
		},
		{
			'title': 'Course on Golang',
			'description': 'A course on Golang for Codefinity. Intented for absolute beginners.',
			'skills': ['Golang', 'Curriculum Development', 'Content Writing'],
			'links': [
				{
					'name': 'codefinity.com/golang',
					'link': 'https://codefinity.com/courses/v2/7fe57095-b8b8-458f-a117-2a9e48c44252'
				}
			]
		},
		{
			'title': 'Course on Redux',
			'description': 'A course on Redux which I wrote for Codefinity.',
			'skills': ['Redux', 'React', 'JavaScript', 'HTML/CSS', 'Curriculum Development', 'Content Writing'],
			'links': [
				{
					'name': 'codefinity.com/redux',
					'link': 'https://codefinity.com/courses/v2/a74c1973-c9e4-430d-af64-5e2637414795'
				}
			]
		},
		{
			'title': 'mcmckain.com',
			'description': 'A website for an author with a ChatGPT based chatbot integration.',
			'skills': ['WordPress', 'HTML/CSS', 'JavaScript'],
			'links': [
				{
					'name': 'mcmckain.com',
					'link': 'http://mcmckain.com/'
				}
			]
		},
		{
			'title': 'Hydrogen',
			'description': 'An exam preparation website for Pakistani students. ',
			'skills': ['Django', 'Bootstrap', 'Python', 'JavaScript', 'HTML/CSS'],
			'links': [
				{ 
					'name': 'hydrogen.com',
					'link': 'https://hydrogen.pythonanywhere.com/'
				},
				{
					'name': 'GitHub',
					'link': 'https://github.com/MouizGhouri/Hydrogen'
				}
			]
		},
		{
			'title': 'A Guide on Pawn Language',
			'description': 'A guide written on Pawn language, intended for people who are absolute beginners to programming.',
			'skills': ['Pawn, Course Writing'],
			'links': [
				{
					'name': 'GitHub',
					'link': 'https://github.com/MouizGhouri/From-A-Little-Clucker-To-A-Spam-Machine/'
				}
			]
		},
		{
			'title': 'Contribution to open.mp',
			'description': 'An article explaining variables in Pawn, on the official open.mp website.',
			'skills': ['Pawn, Content Writing'],
			'links': [
				{
					'name': 'open.mp article',
					'link': 'https://www.open.mp/docs/scripting/language/Variables'
				}
			]
		},
		{
			'title': 'Pawn Scripts',
			'description': 'A collection of mods which I wrote for Gta SA-MP in my early teenage years.',
			'skills': ['Pawn', 'SQL/MySQL'],
			'links': [
				{
					'name': 'GitHub Repository 1',
					'link': 'https://github.com/MouizGhouri/SA-MP'
				}, 
				{
					'name': 'GitHub Repository 2',
					'link': 'https://github.com/MouizGhouri/Project-Aftermath'
				}
			]
		}
	];

	return (
		<div id="page-3">
			<p id="page-3-title">Projects</p>
			<p id="page-3-note">A list of public or non-confidential projects and contributions.</p>
			<hr />
			<div id="projects-div">
				{projects.map((project) =>
					<div className="project">
						<h3>{project['title']}</h3>
						<p>{project['description']}</p>
						<p><span class="project-description-heading">Skills</span>: {project['skills'].join(', ')}</p>
						{project['links'] ? project['links'].map ((link, i) =>
							<p className="link"><span class="project-description-heading">Link {i + 1}</span>: <a target="_blank" rel="noreferrer" href={link['link']}>{link['name']}</a></p>
						) : null}
					</div>
				)}
			</div>
		</div>
	);
}

function CV(props) {
	return (
		<div id="page-2">
			<div id="cv-container">
				<p id="name">Muhammad Mouiz Ghouri</p>

				<div id="cv-links">
					<p className="cv-link">https://github.com/MouizGhouri</p>
					<p className="cv-link">https://github.com/Mou1z</p>
					<p className="cv-link">contact@mouiz.info</p>
				</div>

				<hr />
			
				<p id="intro" className="cv-text">
					Multidisciplinary creator and a Computer Science student. Interested in Web Development, Artificial Intelligence, Mathematics and Management. I have expertise in technical writing and I've written several technical courses for <a href="https://codefinity.com/">Codefinity</a>.
				</p>

				<h2 id="heading-skills">Technical Skills</h2>

				<p id="technical-skills" className="cv-text">
					<ul id="technical-skills-list">

						<li className="skills-list-item">
							HTML / CSS
							<ul>
								<li>Bootstrap</li>
							</ul>
						</li>

						<li className="skills-list-item">
							WordPress
							<ul>
								<li>Elementor</li>
							</ul>
						</li>

						<li className="skills-list-item">
							JavaScript / TypeScript
							<ul>
								<li>Node.JS</li>
								<li>React.JS</li>
								<li>Redux</li>
								<li>Express.JS</li>
								<li>Socket.io</li>
								<li>APIs</li>
								<li>Web Development</li>
							</ul>
						</li>

						<li className="skills-list-item">
							Python
							<ul>
								<li>Creating Automation Tools</li>
								<li>Selenium</li>
								<li>Django</li>
								<li>pyGame</li>
							</ul>
						</li>

						<li className="skills-list-item">
							C / C++
							<ul>
								<li>Object-Oriented Programming</li>
							</ul>
						</li>

						<li className="skills-list-item">
							C#
							<ul>
								<li>Unity Engine Programming</li>
							</ul>
						</li>

						<li className="skills-list-item">
							Structured Query Language (SQL)
							<ul>
								<li>MySQl</li>
								<li>SQLite</li>
							</ul>
						</li>

						<li className="skills-list-item">
							PAWN Language
							<ul>
								<li>Scripting for SA-MP - Advanced</li>
							</ul>
						</li>

						<li className="skills-list-item">
							General
							<ul>
								<li>Regular Expressions (RegEx)</li>
								<li>AutoHotKey (AHK)</li>
							</ul>
						</li>

						<li className="skills-list-item">
							Others (Non-Specialty)
							<ul>
								<li>Web Design</li>
								<li>Graphics Design</li>
								<li>Video Editing</li>
								<li>Microsoft Office</li>
							</ul>
						</li>
					</ul>
				</p>

				<hr />
			</div>
		</div>
	);
}

function About(props) {
	return (
		<div id="page-1">
			<div id="picture"></div>
			<div id="icons">
				<IoMail 			className="icon"	onClick={() => { window.open('mailto:mouizghouri77@gmail.com', '_blank').focus(); }} />
				<IoLogoLinkedin 	className="icon" 	onClick={() => { window.open('https://www.linkedin.com/in/muhammad-mouiz-ghouri-6b9b1a203/', '_blank').focus(); }} />
				<IoLogoGithub 		className="icon" 	onClick={() => { window.open('https://github.com/MouizGhouri', '_blank').focus(); }} />
				<IoLogoSkype 		className="icon" 	onClick={() => { window.open('https://join.skype.com/invite/wwJt83WWuBzk', '_blank').focus(); }} />
				<IoLogoInstagram 	className="icon" 	onClick={() => { window.open('https://www.instagram.com/mouiz.ftw/', '_blank').focus(); }} />
			</div>
			<p id="information">
				{"= {"}
				<br /><br />
				&emsp;&emsp;{'name: "M. Mouiz Ghouri",'}<br/>
				&emsp;&emsp;{'work: "developer & educator"'}
				<br /><br />
				{"};"}
			</p>
			<div id="logo">
				<p id="logo-m">M</p>
				<p id="logo-g">G</p>
			</div>
		</div>
	);
}

function Content(props) {
	const { selections } = props;
	switch (selections[4]) {
		case 0: return <About />;
		case 1: return <CV />;
		case 2: return <Projects />;
		default: {
			return (
				<div>
					ERROR: Page does not exist.
				</div>
			)
		}
	}
}

function Button(props) {
	const { selections, setSelections } = props;
	return (
		<button 
			className="side-bar-button"
			onClick={() => { 
				setSelections ([...selections.slice (1, 5), props.page]);
			}}
		>
			{props.name}
		</button>
	);
}

function Sidebar(props) {
	const { width, selections, setSelections } = props;

	const buttons = [
		{
			'name': 'about',
			'icon': <IoInformation />
		},
		{
			'name': 'curriculum vitae',
			'icon': <IoDocumentSharp />
		}, 
		{
			'name': 'projects',
			'icon': <IoBriefcaseSharp />
		}
	]

	const buttonsRender = buttons.map((button, i) => 
		<Button 	
			selections={selections} 	
			setSelections={setSelections} 		
			page={i} 
			
			name={width > 900 ? button['name'] : button['icon']} 
		/>
	);

	if (selections[4] === 0 || width <= 900) {
		return (
			<div id="side-bar" className={props.classType + 'sidebar'}>
				<div id="sidebar-buttons" className={props.classType + 'buttons'}>
					{buttonsRender}
				</div>
			</div>
		);
	} else {
		return (
			<div id="side-bar" className={props.classType + 'sidebar'}>
				<div id="sidebar-quote">
					"Life has more Meaning in the face of death."
					<br></br>
					- Robert Greene
				</div>
				<div id="sidebar-links">
					<IoMail 			className="sidebar-icon"	onClick={() => { window.open('mailto:mouizghouri77@gmail.com', '_blank').focus(); }} />
					<IoLogoLinkedin		className="sidebar-icon"	onClick={() => { window.open('https://www.linkedin.com/in/muhammad-mouiz-ghouri-6b9b1a203/', '_blank').focus(); }} />
					<IoLogoGithub 		className="sidebar-icon" 	onClick={() => { window.open('https://github.com/MouizGhouri', '_blank').focus(); }} />
					<IoLogoSkype 		className="sidebar-icon" 	onClick={() => { window.open('https://join.skype.com/invite/wwJt83WWuBzk', '_blank').focus(); }} />
					<IoLogoInstagram 	className="sidebar-icon" 	onClick={() => { window.open('https://www.instagram.com/mouiz.ftw/', '_blank').focus(); }} />
				</div>
				<div id="sidebar-buttons" className={props.classType + 'buttons'}>
					{buttonsRender}
				</div>
			</div>
		);
	}
}

function Eden(props) {
	const { selections, setSelections } = props;

	const isSame = (arr1, arr2) => {
		for(let i = 0; i < arr1.length; i++) {
			if(arr1[i] !== arr2[i]) {
				return false;
			}
		}
		return true;
	}

	if(isSame(selections, [-1, -1, -1, -1, -1])) {
		return (
			<div id="eden">
				<div id="eden-main-content">
					<p>Nothing here yet. Come back after 18th Jan 2025.</p>
					<img id="eden-image" className="eden-cat" src={roomImage} alt="back room" />
				</div>
			</div>
		);
	} else {
		return (
			<div id="eden">
				<div id="eden-content">
					<h3>Welcome Visitor! :D</h3>
					<p>You have discovered a hidden passage! Do you want to continue? Do you want to see the unknown?</p>
					<button className="eden-button" onClick={() => setSelections([-1, -1, -1, -1, -1])}>Yes! :D</button>
					<button className="eden-button" onClick={() => setSelections([-1, -1, -1, -1, 0])}>No :/</button>
				</div>
			</div>
		);
	}
}

function Main(props) {
	const [ selections, setSelections ] = useState([-1, -1, -1, -1, 0]);
	const width = window.innerWidth;

	const getClassType = () => {
		if (selections[4] === 0) {
			return 'default-';
		} else {
			return 'detailed-';
		}
	}

	const isSame = (arr1, arr2) => {
		for(let i = 0; i < arr1.length; i++) {
			if(arr1[i] !== arr2[i]) {
				return false;
			}
		}
		return true;
	}

	if(!isSame(selections, [2, 1, 2, 1, 0]) && !isSame(selections, [-1, -1, -1, -1, -1])) {
		return (
			<div id="main">
				<div 
					id="content-div" 
					className={getClassType () + 'content'}
				>
					<Content 
						width={width}

						selections={selections}
						setSelections={setSelections}
					/>
				</div>
				<Sidebar 
					width={width}

					selections={selections}
					setSelections={setSelections}
					classType={getClassType ()} 
				/>
			</div>
		);
	} else {
		return <Eden selections={selections} setSelections={setSelections} />;
	}
}

// ROOT
const root = ReactDOM.createRoot (document.getElementById('root'));
root.render(<Main />);